@import url('https://fonts.cdnfonts.com/css/cera-round-pro');

.dropdown-item {
  cursor: pointer;
}

.activities-list {
  display: inline-flex;
  list-style: none;
}

.activities-list li {
  margin-left: 2rem;
}

.custom-control-label {
  padding-left: 0.5rem !important;
}

.timeline-input {
  display: inline-flex;
  list-style: none;
  padding: 0;
}

.timelinedash {
  padding: 7px 5px;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li a {
  background-color: #1cbb8c !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #1cbb8c;
  text-decoration: none;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number {
  background-color: #1cbb8c !important;
  border: 2px solid #1cbb8c !important;
  color: #fff;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
  border: 2px solid #1cbb8c;
  color: #1cbb8c;
}

.fa-box {
  font-size: 15px !important;
}

.card-body-sure ul {
  list-style: none;
  padding: 0;
}

.card-body-sure ul li {
  display: inline;
  padding-right: 10px;
}

.center {
  margin-top: 2rem;
}

/* ===============
==========Card
======================== */

.container-card {
  background-color: #fff;
  position: relative;
  display: grid;
  /* grid-template-columns: 385px 390px; */
  border-radius: 5px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.356);
  margin-top: 1rem;
  margin-bottom: 1.3rem;
}

.container-card .btn {
  position: absolute;
  bottom: -20px;
  right: -20px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  background-color: #fc9400;
  color: #fff;
  padding: 9px 30px;
  font-size: 1rem;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.294);
}

.container .btn i {
  margin-left: 20px;
  font-size: 1.5rem;
}

.container__text {
  padding: 10px 10px 0;
}

.container__text h1 {
  font-size: bold;
  color: Green;
  font-weight: 700;
}

.container__text .container__text__star span {
  font-size: 0.8rem;
  color: #ffa800;
  margin: -5px 0 20px;
}

.container__text p {
  font-size: 0.9rem;
}

.container__text .container__text__timing {
  display: flex;
  margin: 20px 0 10px;
}

.container__text .container__text__timing .container__text__timing_time {
  margin-right: 25px;
}

.container__text .container__text__timing h2 {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #818189;
}

.container__text .container__text__timing p {
  color: #351897;
  font-weight: bold;
  font-size: 11.7px;
}

.expected_task {
  font-size: medium;
  font-weight: 400;
}

.font-awesome-size {
  font-size: 90px !important;
}

.fa-analytics {
  font-size: 15px !important;
}

.align {
  display: flex;
  align-items: center;
}

.dialog_content {
  width: 500px;
}

.display-8 {
  font-size: 1.3rem;
  text-align: center;
  top: 50%;
  left: 50%;
}

.display-9 {
  font-size: 3rem;
  text-align: center;
}

.packageImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}

.center {
  text-align: center;
}

.profile-ul li {
  display: inline;
  list-style: none;
  margin-left: 10px;
}

.badge-soft-open {
  background-color: LIGHTGRAY;
}

.badge-soft-approved {
  background-color: green;
}

.badge-soft-declined {
  background-color: red;
}

.badge-soft-canceled {
  background-color: Grey;
}

.badge-soft-complete {
  background-color: Blue;
}

.badge-soft-pending {
  background-color: Orange;
}

.badge-soft-Open {
  background-color: LIGHTGRAY;
}

.badge-soft-Approved {
  background-color: green;
}

.badge-soft-Declined {
  background-color: red;
}

.badge-soft-Canceled {
  background-color: Grey;
}

.badge-soft-Complete {
  background-color: #5cb55c;
}

.badge-soft-Reviewing {
  background-color: #b6b32b;
}

.badge-soft-Pending {
  background-color: Orange;
}

.badge-soft-Processing {
  background-color: #f55d30;
}

.badge-soft-Order_Received {
  background-color: LIGHTGRAY;
}

.badge-soft-Payment_Confirmed {
  background-color: green;
}

.badge-soft-Declined {
  background-color: red;
}

.badge-soft-Delivered {
  background-color: rgba(32, 143, 28, 0.651);
}

.badge-soft-Awaiting_Payment {
  background-color: #466368;
}

.badge-soft-Reviewing_Order {
  background-color: Orange;
}

.badge-soft-Order_in_Transit {
  background-color: #f55d30;
}

.badge-soft-Request_Sent {
  background-color: #cf785d;
}

.card-shadow {
  box-shadow: 1px 2px 3px 2px rgb(0 0 0 / 8%);
}

.chart-size {
  width: 60%;
  margin: auto;
}

.bar-charts {
  width: 100%;
  height: 100%;
  margin: auto;
}

.claim-card {
  text-align: center;
  line-height: 285px;
}

.product-card {
  text-align: center;
  line-height: 155px;
}

.channel-card {
  text-align: center;
  line-height: 288px;
}

@media only screen and (max-width: 320px) {
  .channel-card {
    max-width: 100%;
    margin: 'auto';
    line-height: 220px;
  }
}

.feed-card {
  text-align: center;
  line-height: 300px;
}

.text-primary {
  color: #1d6639 !important;
}

.btn-add {
  background: #1d6639;
  border-radius: 5px;
}

.account-card {
  background-color: #1d6639 !important;
  color: #fff;
}

.account-card p {
  background-color: #1d6639 !important;
  color: #fff;
}

.account-card h5 {
  background-color: #1d6639 !important;
  color: #fff;
}

.body-green {
  background-color: #1d6639 !important;
  color: #fff;
  border-radius: 20px;
}

.icon-size {
  font-size: 60px !important;
}

.ft-list {
  padding: 0;
  list-style: none;
}

.ft-list li {
  display: inline;
}

.fa-copy {
  margin-left: 10px;
}

.benefit-list {
  list-style: none;
  padding: 0;
}

.benefit-list li {
  padding-bottom: 2px;
}

#pluswrap {
  position: absolute;
  width: 100%;
  height: 50vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  top: 100;
  left: 100;
}

.plus {
  display: flex;
  margin: 0 auto;
}

/* Activate Page */
.input-container {
  height: 100%;
}

a {
  color: inherit;
}

a:hover {
  color: #7f8ff4;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}

.card-radio-label {
  display: inline-block !important;
}

.btn--primary {
  background: #268501fb;
  color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 12px 36px;
}

.btn--primary:hover {
  background: #6dd444;
  color: #fff;
}

.btn--primary:active {
  background: #6dd444;
  box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
}

.btn--inside {
  margin-left: -96px;
}

.form__field {
  max-width: 100%;
  background: #fff;
  color: #a3a3a3;
  font: inherit;
  box-shadow: 8px 10px 10px 10px rgba(0, 5, 1, 0.1);
  border: 0;
  outline: 0;
  padding: 22px 18px;
}

/*============= 
Count Down Modal 
==============*/

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 0.8rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.3rem 0 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  font-size: 0.7rem;
  line-height: 1rem;
}

/* =========== */
/* ===Modal=== */
/* =========== */
.modal-confirm {
  color: #434e65;
  max-width: 100%;
}

.modal-confirm .modal-content {
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 7px grey;
}

.modal-confirm .modal-header-true {
  background: #47c9a2;
  border-bottom: none;
  position: relative;
  text-align: center !important;
  margin: -20px -20px 0;
  border-radius: 5px 5px 0 0;
  padding: 35px;
}

.modal-confirm .modal-header-false {
  background: #d13e3e;
  border-bottom: none;
  position: relative;
  text-align: center !important;
  margin: -20px -20px 0;
  border-radius: 5px 5px 0 0;
  padding: 35px;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 36px;
  margin: 10px 0;
}

.modal-confirm .form-control,
.modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}

.modal-confirm .close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  text-shadow: none;
  opacity: 0.5;
}

.modal-confirm .close:hover {
  opacity: 0.8;
}

.modal-confirm .icon-box {
  color: #fff;
  text-align: center !important;
}

.modal-confirm .icon-box i {
  border-radius: 50%;
  display: inline-block;
  z-index: 9;
  border: 5px solid #fff;
  padding: 15px;
  font-size: 62px;
  margin: -5px 0 0 -5px;
  text-align: center !important;
}

.modal-confirm.modal-dialog {
  margin-top: 50px;
}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #eeb711;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border-radius: 30px;
  margin-top: 10px;
  /* padding: 6px 20px; */
  border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
  background: #eda645;
  outline: none;
}

.model-text {
  font-size: 16px !important;
  font-weight: 500;
}

.modal-confirm .btn span {
  margin: 1px 3px 0;
  float: left;
}

.modal-confirm .btn i {
  margin-left: 1px;
  font-size: 20px;
  float: right;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.font-circle {
  text-align: center !important;
  justify-self: center !important;
  margin-left: 170px;
}

.box {
  background: rgb(108, 216, 108);
  padding: 0.3rem 0.7rem 0rem 0.7rem;
  border: 0.2rem, solid, rgb(108, 216, 108);
  border-radius: 1rem;
  color: #fff;
  font-family: sans-serif;
}

.point-box {
  border: 0.2rem, solid, rgb(108, 216, 108);
  background: rgb(108, 216, 108);
  border-radius: 0.5rem;
  padding: 2.5rem 0.5rem 2.5rem 0.5rem;
  color: #fff;
  font-family: sans-serif;
  text-align: center;
}

.color {
  color: red;
}

.form-center {
  display: table;
  width: 100%;
}

.status-form {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.point-font {
  font-size: clamp(38px, 13vw, 30%);
}

.compliance-points {
  font-size: 12px;
  font-weight: 500;
  color: black;
}

@media screen and (max-width: 768px) {
  .point-font {
    font-size: clamp(24px, 13vw, 30%);
    margin: auto;
  }

  .compliance-points {
    font-size: 13px;
    width: 80%;
    font-weight: 500;
    color: black;
    float: none;
  }
}

.text-line {
  font-family: sans-serif;
  margin: 28px auto;
  text-align: center;
  font-size: 15px;
  max-width: 500px;
  position: relative;
}

.text-line:before {
  content: '';
  display: block;
  width: 30px;
  height: 5px;
  background: #9595f5;
  left: 0;
  top: 40%;
  position: absolute;
}

.text-line:after {
  content: '';
  display: block;
  width: 30px;
  height: 5px;
  background: #9595f5;
  right: 0;
  top: 40%;
  position: absolute;
}

@media only screen and (max-width: 290px) {
  .text-line {
    font-family: sans-serif;
    margin: 28px auto;
    text-align: center;
    font-size: 12px;
    max-width: 300px;
    position: relative;
  }

  .text-line:before {
    width: 10px;
  }

  .text-line:after {
    width: 10px;
  }
}

@media only screen and (min-width: 300px) {
  .text-line {
    font-family: sans-serif;
    margin: 28px auto;
    text-align: center;
    font-size: 14px;
    max-width: 300px;
    position: relative;
  }

  .text-line:before {
    width: 30px;
  }

  .text-line:after {
    width: 30px;
  }
}

@media only screen and (min-width: 1280px) {
  .text-line {
    font-family: sans-serif;
    margin: 28px auto;
    text-align: center;
    font-size: 14px;
    max-width: 300px;
    position: relative;
  }

  .text-line:before {
    width: 20px;
  }

  .text-line:after {
    width: 20px;
  }
}

.button-outline {
  background-color: white;
  color: black;
  padding: 8px !important;
  transition-duration: 0.4s;
}

/* Green */
.success {
  border-color: #04aa6d;
  color: green;
}

.success:hover {
  background-color: #04aa6d;
  color: white !important;
}

/* Total Section */
.totals .totals-item {
  float: right;
  clear: both;
  width: 100%;
  margin-bottom: 10px;
}

.totals .totals-item label {
  float: left;
  clear: both;
  width: 79%;
  text-align: right;
  font-size: 17px;
}

.totals .totals-item .totals-value {
  float: right;
  width: 21%;
  text-align: right;
  font-size: 17px;
}

.grand-total {
  border-top: 3px solid grey !important;
}

.card-border {
  border-right: 2px solid grey;
}

.info-box {
  padding: 10px;
}

.rounded {
  min-height: 120px;
}

.preloader {
  width: 9%;
  margin: auto;
}

.message {
  resize: none;
}

.delete-mail {
  padding-left: 40px;
}

.message-text {
  display: block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 480px) {
  .preloader {
    width: 25%;
    margin: auto;
  }
}

.apex-charts {
  width: 80%;
  height: 50%;
  margin: auto;
}

.table-body {
  width: 100%;
  display: block;
  height: 400px;
  /* Just for the demo          */
  overflow-y: auto;
  /* Trigger vertical scroll    */
  overflow-x: hidden;
  /* Hide the horizontal scroll */
}

.red_blink {
  width: 13px;
  height: 13px;
  background: red;
  border-radius: 50%;
  background-color: red;
  animation: blinker 1s linear infinite;
}

.green_blink {
  width: 13px;
  height: 13px;
  background: rgb(1, 54, 1);
  border-radius: 50%;
  background-color: rgb(1, 54, 1);
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* Status Page  */

.farmtrove-logo {
  width: 70% !important;
  height: 100% !important;
}

@media (max-width: 991px) {
  .farmtrove-logo {
    height: 50 !important;
  }
}

.status-text {
  margin-top: 20px;
  color: #f56e22 !important;
  font-size: 13px;
  font-weight: 800;
}

.check button {
  background-color: #268727 !important;
}

.check button:hover {
  background-color: #22bd25 !important;
}

.image-container {
  height: 70vh;
}

.user-image {
  max-width: 150px;
  max-height: 150px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.profile-image {
  max-width: 250px;
  height: 250px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

@media (max-width: 991px) {
  .image-container {
    height: 50vh;
  }

  .profile-image {
    width: 250px;
  }
}

.container-cropper {
  height: 90%;
  padding: 10px;
}

.cropper {
  height: 90%;
  position: relative;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 60%;
}

.container-buttons {
  border: 1px solid #f5f5f5;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cameraIcon {
  height: 4rem;
  width: 4rem;
  position: absolute;
  bottom: 7rem;
  right: 100px;
  background: white;
  font-size: 25px;
  border-radius: 50%;
  margin: auto;
  padding: 1.1rem;
  cursor: pointer;
}

@media (max-width: 991px) {
  .cameraIcon {
    height: 4rem;
    width: 4rem;
    position: absolute;
    bottom: 7rem;
    right: 0px;
    background: white;
    font-size: 25px;
    border-radius: 50%;
    padding: 1.1rem;
  }
}

.cameraIcon:hover {
  background-color: white;
}

.profile-circle {
  width: 180px;
  height: 180px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.user-image-status {
  width: 250px;
  height: 250px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

@media (max-width: 991px) {
  .user-image-status {
    width: 270px;
    height: 270px;
  }
}

.farmer-user-image {
  max-width: 100%;
  max-height: 100%;
  margin-top: 10px;
  max-height: calc(80vh - 80px);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

@media (max-width: 991px) {
  .farmer-user-image {
    width: 200px;
    height: 200px;
    margin-top: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border-right: none;
  }
}

@media (max-width: 420px) {
  .farmer-user-image {
    width: 220px;
    height: 220px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border-right: none;
  }
}

/* Service Provider Dashboard */
.service-farmtrove-logo {
  width: 40% !important;
  height: 100% !important;
}

.service-dashboard-page-content {
  height: 75vh !important;
  min-height: 75vh !important;
}

@media (max-width: 991px) {
  .service-farmtrove-logo {
    height: 50 !important;
  }
}

/* All User Interface */
.breadcrumb-item {
  margin: 5px;
  padding: 5px;
}

.rvm-breadcrumb-item::before {
  float: left;
  color: #74788d;
  content: none !important;
}

.status-border {
  border: solid 1px #74788d;
  border-radius: 8px;
}

.MuiPaper-root {
  color: inherit !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.onHover :hover {
  color: white;
}

#form-control-input {
  padding: 5px 5px 5px 10px;
  background-color: #74788d;
}

.card-radio-label {
  display: block !important;
}

.label {
  display: inline-block;
}

.card-scan-activity {
  color: white !important;
  background-color: rgb(131, 103, 26) !important;
}

.card-record-activity {
  color: white !important;
  background-color: rgb(68, 131, 26) !important;
}

.product-img {
  max-width: 300;
}

.card-icons {
  width: 190px;
  padding: 0.4rem 0.4rem;
  background-clip: border-box;
  border: 0 solid #f1f5f7;
  border-radius: 0.25rem;
}

.card-box {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards__item {
  display: flex;
  flex-grow: 3;
  padding: 0.2rem;
}

.cards__item:nth-of-type(1) {
  min-width: 67%;
}

@media (min-width: 40rem) {
  .cards__item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards__item {
    width: 30.3333%;
  }
}

.box_content {
  border-color: #1cbb8c !important;
  border-radius: 0.25rem;
  padding: 0.9rem 0.9rem;
  box-shadow: 1px 7px 22px -9px rgb(0 0 0 / 50%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 700px;
}

.centerActivity {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(75vh - 50px);
  text-align: center !important;
}

.centerBenefit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(13vh - 50px);
  text-align: center !important;
  color: #fff;
}

.scroll {
  height: calc(60vh - 50px);
  overflow-y: scroll;
}

/* width */
.scroll::-webkit-scrollbar {
  width: 10px;
  scrollbar-color: #6969dd #e0e0e0;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d4aa70;
  border-radius: 100px;
}

.protocol-point-danger-card {
  background-color: rgb(223, 45, 45);
  color: white !important;
  font-weight: bolder;
}

.protocol-point-success-card {
  background-color: green;
  color: white !important;
  font-weight: bolder;
}

.point-div span {
  background-color: transparent !important;
}

.benefit-card {
  background-color: #3f51b5 !important;
  margin-bottom: 0px !important;
}

.table-style {
  color: #fff !important;
}

.standard-body {
  position: absolute;
  top: -20px;
  /* top: -30px; */
  left: 40px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  background-color: #00b050;
  color: #fff;
  padding: 9px 30px;
  font-size: 1rem;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.294);
}

.img-placeholder {
  background-color: rgb(189, 189, 189);
  border-radius: 20px;
  width: 25em;
  height: 30em;
  margin: auto;
  margin-top: 20px;
}

.item-1 {
  font-weight: 900 !important;
  text-align: center;
}

.item-1 .fa-coins {
  font-size: 23px;
  border-radius: 50%;
  padding: 10px;
}

.item-1 .fa-hourglass-half {
  font-size: 22px;
  border-radius: 50%;
  padding: 13px 15px;
}

.item-1 .fa-calendar-day {
  font-size: 22px;
  border: 1px solid grey;
  border-radius: 50%;
  padding: 12px 14px;
}

.item-1 .fa-shield-check {
  font-size: 22px;
  border-radius: 50%;
  padding: 12px 14px;
}

.item-1 .fa-bullseye-arrow {
  font-size: 22px;
  border-radius: 50%;
  padding: 12px 12px;
}

.item-2 {
  font-size: 22px;
  margin-bottom: 0px !important;
  font-weight: 900 !important;
}

.card-border-radius {
  border-radius: 20px !important;
  background-color: #93c400;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.date-card {
  padding: 3px;
  width: 38px;
  text-align: center;
  border-radius: 20px;
}

.date-card h6 {
  margin-top: 20px;
  color: #fff;
  margin-bottom: 5px;
  font-size: 15px;
}

.weather-detail {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 13px;
}

.weather-detail li {
  display: inline-block;
}

.temp-detail {
  list-style: none;
  margin: 0;
  padding: 0;
}

.temp-detail li {
  display: inline-block;
  margin-right: 10px;
}

.weather-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weather-icon img {
  max-width: 100%;
  margin: auto;
  vertical-align: bottom;
  position: relative;
  bottom: 27px;
}

.card_activities {
  border-radius: 20px;
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.25),
    -1px -1px 1px 1px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.4s;
}

.card_activities .card_image_scan {
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 10px;
  background-color: #f9e5ee;
  text-align: center;
  color: #fff;
}

.card_activities .card_image_record {
  /* width: 120px;
  height: 120px; */
  margin: auto;
  border-radius: 10px;
  background-color: #c7e8f7;
  text-align: center;
  color: #fff;
}

.card_activities .card_title {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
}

.card_activities:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 1px 1px 14px 1px rgba(0, 0, 0, 0.25),
    -1px -1px 14px 1px rgba(0, 0, 0, 0.22);
}

.color-bar {
  background-color: #f5f5f5;
  border-radius: 20px 0px 0px 20px;
  padding-top: 3px;
  padding-bottom: 3px;
}

@media only screen and (max-width: 600px) {
  .color-bar {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
}

.card-design {
  padding: 0;
}

.flex-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.25),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.4s;
  max-width: 100%;
  margin: 10px;
}

.flex-contain:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 1px 1px 14px 1px rgba(0, 0, 0, 0.25),
    -1px -1px 14px 1px rgba(0, 0, 0, 0.22);
}

.item {
  height: 100px;
  /* padding: calc(5vh - 18px); */
  padding: 2.5vh;
}

.item:nth-of-type(2) {
  width: 32%;
}

.item:nth-of-type(3) {
  width: 20%;
}

@media only screen and (max-width: 600px) {
  .item:nth-of-type(2) {
    width: 300px;
    height: 60px;
  }

  .item:nth-of-type(3) {
    width: 200px;
    height: 70px;
  }
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

.activity-container {
  display: flex;
  justify-content: space-between;
}

.activity-icons {
  padding: 0px;
  margin-bottom: 5px;
  color: #ada8b2 !important;
}

.activity-icons li {
  display: inline-block;
  list-style: none;
}

.primary-text {
  position: relative;
  bottom: 4px;
}

.item-list {
  padding: 0px;
  margin-top: 10px;
}

.item-list li {
  display: inline-block;
  list-style: none;
  background-color: burlywood;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.product-img {
  border: 1px solid #eff2f7;
  padding: 24px;
  max-width: 250px;
  margin: auto;
  text-align: center;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #4caf50 !important;
  border-radius: 20px !important;
}

.MuiLinearProgress-colorPrimary {
  border-radius: 50px !important;
}

.user-name {
  text-transform: capitalize;
}

/*=========Recent-post==========*/

.owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 7px 12px !important;
  background: #4caf50 !important;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute !important;
  bottom: 100% !important;
  left: 90%;
}

@media (max-width: 414px) {
  .owl-theme .owl-nav {
    left: 73%;
  }
}

.scanner-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px !important;
}

.scanner-container {
  padding-top: 0 !important;
}

/* Other Farmcycle list */
.course {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  max-width: 97%;
  overflow: hidden;
  /* width: 700px; */
}

@media (max-width: 420px), screen and (orientation: portrait) {
  .course {
    flex-wrap: wrap;
  }
}

.course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
}

.course h2 {
  letter-spacing: 1px;
  margin: 10px 0;
  font-size: 12px;
  width: 85%;
}

.course-preview {
  background-color: #1d6639;
  color: #fff;
  padding: 30px;
  padding-right: 10px;
  width: 40%;
  max-width: 250px;
}

@media (max-width: 420px), screen and (orientation: portrait) {
  .course-preview {
    width: 100%;
    max-width: 400px;
  }
}

.course-info {
  padding: 30px;
  position: relative;
  width: 100%;
}

.progress-container {
  position: absolute;
  top: 30px;
  right: 30px;
  text-align: right;
  width: 100px;
}

.progress {
  border-radius: 3px;
  height: 5px;
  width: 100%;
}

.progress-text {
  font-size: 10px;
  opacity: 0.6;
  letter-spacing: 1px;
}

.cycle-btn {
  background-color: #1d6639;
  border: 0;
  border-radius: 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  /* padding: 12px 12px; */
  position: absolute;
  bottom: 15px;
  right: 20px;
  letter-spacing: 1px;
}

@media (max-width: 420px), screen and (orientation: portrait) {
  .cycle-btn {
    bottom: 24px;
  }
}

.cycle-btn:hover {
  color: #fff;
  background-color: #265f56;
}

.tailspin {
  height: calc(75vh - 100px);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tail-spin-cycle {
  height: calc(50vh - 100px);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.empty-cycle-container {
  height: calc(50vh - 100px);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.count-image {
  border: 1px solid #eff2f7;
  padding: 24px;
  max-width: 300px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 96%;
}

.count-down-image {
  display: flex;
  align-self: center;
  justify-content: space-evenly;
}

.text-cap {
  text-transform: capitalize;
}

.icon_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(15vh - 30px);
}

/* Farm Cycle Details */
.cycle__flex__container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;
}

/* Farm Cycle Details */
.cycle__flex__container .card__mouse {
  margin-right: 50px;
  margin-bottom: 10px;
  width: 230px;
  min-width: 200px;
}

.cycle-benefit-card i {
  font-size: 80px;
  margin-bottom: 20px;
}

.reward__container {
  max-height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-self: center;
}

.cycle__card {
  background-image: linear-gradient(to bottom, #bdd3b7, #197033);
  border-radius: 20px;
  color: #fff !important;
  min-width: 190px;
  min-height: 270px;
  padding: 7px 0px 25px 0px;
}

.cycle__card:hover {
  background-image: linear-gradient(to bottom, #85a57c, #054e1b);
  border-radius: 20px;
  color: #fff !important;
  width: 190px;
  height: 270px;
  padding: 7px 0px 25px 0px;
}

.scroll--yaxis {
  width: 100%;
  height: 271px;
  overflow-x: auto;
  /* Hide the horizontal scroll */
  overflow-y: auto;
  /* Hide the horizontal scroll */
}

/* width */
.scroll--yaxis::-webkit-scrollbar {
  width: 5px;
  scrollbar-color: #6969dd #e0e0e0;
}

/* Track */
.scroll--yaxis::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.scroll--yaxis::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d4aa70;
  border-radius: 100px;
}

.comment--card {
  background-color: #dbd4ff;
}

/* testing */

.card__mouse {
  position: relative;
  max-width: 190px;
  max-height: 210px;
  padding-bottom: 62px;
  background: #191919;
  border-radius: 20px;
  overflow: hidden;
}

.card__mouse::before {
  content: '';
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background: #1d6639;
  transform: skewY(345deg);
  transition: 0.5s;
}

.card__mouse:hover::before {
  top: -70%;
  transform: skewY(390deg);
}

.card__mouse::after {
  content: 'FARMSURED';
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 'auto';
  text-align: center;
  font-weight: 700;
  font-size: 2.1em;
  color: rgba(214, 211, 211, 0.1);
}

.card__mouse .imgBox {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  z-index: 1;
  font-size: 50px;
  color: #fff;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.card__mouse .contentBox {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.card__mouse .contentBox h5 {
  font-size: 13px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.card__mouse .contentBox .buy {
  position: relative;
  top: 10px;
  opacity: 0;
  padding: 10px 20px;
  margin-top: 4px;
  margin-bottom: 2px;
  font-size: 11px;
  color: #fff;
  text-decoration: none;
  background: #068d2f;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.5s;
}

.card__mouse:hover .contentBox .buy {
  top: 0;
  opacity: 1;
}

.empty__card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  height: 22vh;
}

.empty__card .fa-times-circle {
  font-size: 50px;
  margin-bottom: 5px;
  color: red;
}

.card__overlay::after {
  content: '';
  /* ::before and ::after both require content */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #e1e442, #33d0ff);
  border-radius: 15px;
  opacity: 0.9;
}

.card__overlay__text::before {
  content: 'Coming Soon';
  /* ::before and ::after both require content */
  position: absolute;
  top: 40%;
  left: 20%;
  width: 100%;
  height: 100%;
  color: black;
  font-size: 20px;
  font-weight: 900;
}

.eop-item {
  flex: 1;
}

.eop-item h4 {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 1px;
}

.eop-item p {
  font-size: 13px;
  padding-top: 2px;
  margin-bottom: 0;
}

.eop-item i {
  font-size: 22px;
}

.separator {
  border-right: 4px solid black;
  height: 100%;
  margin: 0 10px;
}

/*================================================
==================================================
======== Admin user farm cycle detail page =======
==================================================
==================================================*/

.text-capitalize {
  text-transform: capitalize;
}

.btn-custom-outline {
  color: #fff;
}

/*================================================
==================================================
======== Admin label generation page =============
==================================================
==================================================*/

.green_tag {
  background-image: url('green_tag.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 150px;
}

.red_tag {
  background-image: url('red_tag.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 150px;
}

/* =============================================== 
==================================================
================ Flip Card Css ===================
==================================================
=================================================*/

.flippy-front,
.flippy-back {
  background: white !important;
}

/* =================================================
====================================================
==============YEAP SAfER Form ======================
====================================================
===================================================*/
@media (max-width: 425px) {
  .container-height {
    height: 100% !important;
  }

  .yeap-safer-logo {
    width: 100% !important;
  }
}

/* ====================================================
=======================================================
======================Status Page======================\
========================================================
====================================================== */

.status-benefit-card {
  background-color: green;
  border-radius: 40px 0 0 40px;
}

.benefit-card-body {
  padding: 10px;
  color: white;
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px !important;
}

.farmsured-benefit-icon {
  width: 15%;
  margin: auto;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  right: -8px;
}

/* ====================================================
=======================================================
=================Farmer Wallet Page====================\
========================================================
====================================================== */

.balance-body {
  padding: 25px !important;
}

.balance-title {
  font-weight: 500;
}

.wallet-bal {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 800;
}

.widget-icon {
  width: 48px;
  height: 48px;
  background-color: #fafafa;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.balance-container {
  display: flex;
  align-items: center;
}

.balance-item {
  width: 40%;
  /* Adjust the width as per your requirements */
  padding-top: 10px;
}

@media (max-width: 425px) {
  .balance-item {
    width: 100%;
    /* Adjust the width as per your requirements */
    padding-top: 10px;
  }
}

.balance-profit i {
  background-color: rgb(199, 199, 199);
  padding: 14px;
  color: #fff;
  border-radius: 10px;
  margin-right: 10px;
}

.balance-profit small {
  font-size: 15px;
  color: black;
  font-weight: 900;
}

.balance-list li {
  display: inline-block;
}

.transaction-list {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.transaction-items:nth-of-type(1) {
  width: 70%;
}

.transaction-items:nth-of-type(2) {
  width: 100%;
  padding-left: 25px;
}

.transaction-items:nth-of-type(3) {
  width: 20%;
}

.transaction-list .avatar-xs {
  height: 4rem;
  width: 4rem;
}

@media only screen and (max-width: 600px) {
  .transaction-list .avatar-xs {
    height: 2rem;
    width: 2rem;
  }

  .transaction-list {
    flex-wrap: wrap;
  }

  .transaction-items:nth-of-type(1) {
    width: 10%;
  }

  .transaction-items:nth-of-type(2) {
    width: 55%;
    padding-left: 15px;
    font-size: 12px;
  }

  .transaction-items:nth-of-type(3) {
    width: 32%;
    font-size: 14px;
    text-align: end;
  }
}

.transaction-list::before {
  border-left: none !important;
}

.action-btn .btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  border-radius: 0.8rem;
}

.action-btn span {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .action-btn-container .flex-column {
    flex-direction: row !important;
  }

  .action-btn .btn {
    margin-top: 0px !important;
    margin: 10px !important;
  }
}

.send__dialog__card {
  background-color: '#E8F0F2' !important;
}

.send-dialog-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.send-dialog-item:nth-of-type(1) {
  width: 50%;
}

.farmtrove-banner {
  max-width: 100%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .farmtrove-banner {
    max-width: 100%;
    margin-bottom: 25px;
  }

  .send-dialog-container {
    flex-wrap: wrap;
  }

  .send-dialog-item:nth-of-type(1) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.card-empty-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  margin: 0;
}

.card-empty {
  width: 300px;
  text-align: center;
  padding: 20px;
}

.card-empty-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-empty-text {
  font-size: 14px;
}

.send-amount {
  font-size: 30px;
  font-weight: 700;
  margin-top: 5px;
}

.send-detail-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
  margin-top: 13px;
}

.send-item {
  height: 20px;
  font-size: 18px;
  font-size: 14px;
}

.send-item:nth-of-type(1) {
  width: 30%;
}

.send-item:nth-of-type(2) {
  width: 70%;
}

.transfer-info-card {
  background: linear-gradient(49deg, #268727 30.22%, #9ecf14 90.65%);
  border-radius: 1rem;
}

.transaction-detail-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
  margin-top: 13px;
}

.transaction-item {
  height: 20px;
  color: #000000;
  font-size: 18px;
  font-size: 14px;
}

.transaction-item:nth-of-type(1) {
  width: 40%;
}

.transaction-item:nth-of-type(2) {
  width: 60%;
}

.vertical-line {
  border: none;
  border-left: 2px solid #333;
  height: 60px !important;
  /* margin: auto; */
  margin: 30px;
}

/* ====================================================
=======================================================
=====================FarmGPT Page======================
========================================================
====================================================== */

.thinking-dots span::after {
  content: ' ';
  /* Empty space to represent dots */
  display: inline-block;
  animation: blink 5s infinite;
}

@keyframes blink {
  0% {
    content: '';
  }

  50% {
    content: '.';
  }

  100% {
    content: '..';
  }
}

@media only screen and (max-width: 600px) {
  .loading-chat-history {
    width: 100%;
  }

  .ctext-wrap-content p pre {
    font-size: 12px !important;
  }
}

/* ====================================================
=======================================================
===========Admin View Loan Application  Page===========
========================================================
====================================================== */
.status-button {
  padding: 20px 50px 20px 50px !important;
}

/* ====================================================
=======================================================
=========== Admin Edit Package Application  Page=======
========================================================
====================================================== */

/* .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1cbb8c !important;
} */

/* ====================================================
=======================================================
=========== Admin New Package Page =====================
========================================================
====================================================== */

.input-container-package {
  display: flex;
  align-items: center;
  gap: 20px;
  /* margin-top: 20px; */
}

.input-container-package input {
  width: 80%;
  border-radius: 10px;
}

.input-container-package input:focus {
  outline: 1.5px solid #2485EE;
}

.delete-btn {
  width: 70px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  background-color: #ffffff;
  border: 1.5px solid #ff0000;
  border-radius: 5px;
  transition: all 0.4s ease;
  cursor: pointer;
}

.delete-btn:hover {
  color: #ffffff;
  background-color: #ff0000;
}

.add-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #2485EE;
  background-color: #ffffff;
  border: 1.5px solid #2485EE;
  border-radius: 5px;
  padding: 6px 20px;
  margin-top: 10px;
  margin-bottom: 12px;
  transition: all 0.4s ease;
  cursor: pointer;
}


.add-btn:hover {
  color: #ffffff;
  background-color: #2485EE;
}